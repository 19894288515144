import React from "react";
import { Redirect } from "react-router-dom";

// Authentication related user
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";

  // Dashboard
import Dashboard from "../pages/Dashboard/index";
import Employees from "../pages/user/employees";
import Investors from "../pages/user/investors";
import Home from "../pages/home/home";
import NewLogin from "../pages/home/NewLogin";
import Contact from "../pages/home/contact";
import Lending from "../pages/home/Lending";
import Investwithus from "../pages/home/investwithus";
import About from "../pages/home/about";
import Portfolio from "../pages/home/Portfolio";
import SinglePortfolio from "../pages/home/singlePortfolio";
import Listings from "../pages/home/Listings";
import Listingslist from "../pages/home/Listingslist";
import ListingsDetails from "../pages/home/ListingsDetails";
import CurrentProperties from "../pages/user/CurrentProperties";
import CurrentPropertiesfind from "../pages/user/CurrentPropertiesfind";
import EmploymentContract from "../pages/user/EmploymentContract";
import EmployeeHandbook from "../pages/user/EmployeeHandbook";
import Training from "../pages/user/Training";
import logout from "../pages/Authentication/Logout";
// about page
import Overview from "../pages/home/overview";
import History from "../pages/home/history";
import Values from "../pages/home/ourValues";
import Team from "../pages/home/ourTeam";

// investment page
import InvestmentStrategy from "../pages/home/investment-strategy";
import InvestmentOppotunities from "../pages/home/investment-opportunities";
import ClearlyDefined from "../pages/home/clearly-defined";
import InvestNow from "../pages/home/invest-now";

// media page
import Testmonials from "../pages/home/testmonials";
import Podcast from "../pages/home/podcast";
import Videos from "../pages/home/videos";
import NewsLetter from "../pages/home/news-letter";
import SingleNewsLetter from "../pages/home/news-letter-detail";
import PressRelease from "../pages/home/press-release";
import SocialMedia from "../pages/home/social-media";

const authProtectedRoutes = [

	{ path: "/dashboard", component: Dashboard },
	{ path: "/employees", component: Dashboard },
	{ path: "/investors", component: Dashboard },
	// this route should be at the end of all other routes
	{ path: "/home", exact: true, component: () => <Redirect to="/home" /> },
	{ path: "/current-properties/:id", exact: true,  component: CurrentPropertiesfind },
	{ path: "/current-properties", exact: true, component: CurrentProperties },
	{ path: "/employment-contract", component: EmploymentContract },
	{ path: "/employment-handbook", component: EmployeeHandbook },
	{ path: "/training", component: Training },
];

const publicRoutes = [
	{ path: "/logout",exact:true, component: logout},
	{ path: "/login",exact:true, component: NewLogin },
	//{ path: "/login", component: () => <Redirect to="/" /> },
	// { path: "/forgot-password", component: ForgetPwd },
	// { path: "/register", component: Register },
	{ path: "/home", exact:true	, component: Home  },
	{ path: "/contact", exact:true	, component: Contact  },
	{ path: "/listings-detail/:id", exact:true, component: ListingsDetails  },
	{ path: "/listings/:type/:location", exact:true, component: Listingslist  },
	{ path: "/listings", exact:true, component: Listings  },
	{ path: "/lending", exact:true, component: Lending  },
	{ path: "/invest-with-us", exact:true, component: Investwithus  },
	{ path: "/about", exact:true, component: About  },
	{ path: "/overview", exact:true, component: Overview  },
	{ path: "/history", exact:true, component: History  },
	{ path: "/our-values", exact:true, component: Values  },
	{ path: "/our-team", exact:true, component: Team  },
	{ path: "/investment-strategy", exact:true, component: InvestmentStrategy  },
	{ path: "/investment-opportunities", exact:true, component: InvestmentOppotunities  },
	{ path: "/clearly-defined-acquisition-criteria", exact:true, component: ClearlyDefined  },
	{ path: "/invest-now", exact:true, component: InvestNow  },
	{ path: "/testimonials", exact:true, component: Testmonials  },
	{ path: "/podcast", exact:true, component: Podcast  },
	{ path: "/videos", exact:true, component: Videos  },
	{ path: "/news-letter", exact:true, component: NewsLetter  },
	{ path: "/single-news-letter/:id", exact:true, component: SingleNewsLetter  },
	{ path: "/press-release", exact:true, component: PressRelease  },
	{ path: "/social-media", exact:true, component: SocialMedia  },
	{ path: "/portfolio", exact:true, component: Portfolio  },
	{ path: "/singleportfolio/:id", exact:true, component: SinglePortfolio  }
];

export { authProtectedRoutes, publicRoutes };
