import React, {useEffect, useState} from 'react';
import {Link, useHistory} from "react-router-dom";
import {Row, Col, Card, CardBody, CardTitle,FormGroup,Label,Input, CardSubtitle, UncontrolledTooltip, Modal} from "reactstrap";
import Footer from "../../layout/footer";
import HeaderNew from './headerNew'
import Mainsidebar from "./mainsidebar";
import 'react-fancybox/lib/fancybox.css'
import '../../assets/css/home.css'
import Iframe from 'react-iframe'
const axios = require('axios');

const About = (props) => {

	const [Contact, setContact] = useState(false);
	const [tab, settab] = useState('COMPLETED PROJECTS');
	const [model, setmodel] = useState(false);
	const [loader, Setloader] = useState(true);


	useEffect(() => {
		window.scrollTo(0, 0)
		getdatatoshow()
	}, []);

	const getdatatoshow = () => {
		axios.get(process.env.REACT_APP_BASE_URL+"auth/portfoliodetails")
			.then(function (response) {
				if(response.data.error == 0)
					setContact(response.data.list)
			})
			.catch(function (error,response) {
				console.log("error",error)
			})
			.then(function () {
				Setloader(false)
			});

	};
	var sectionStyle = {
		backgroundImage: `url(${Contact.image})`
	};
	const handleClose=()=>{
		return  true;
	}



	return (
		<>
			<HeaderNew homepage={false}/>
			<section className="slider-section about-banner" alt="portfolio-image">
				<img src="/portfolio.jpeg"/>
				<h2 className="text-center">Portfolio</h2>
			</section>

			<section className="porfolio">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="port-list">
                            <ul className="nav nav-tabs">
                                <li className="nav-item">
								<a className={tab =="COMPLETED PROJECTS"?'active':''} data-toggle="tab" onClick={()=>{settab('COMPLETED PROJECTS')}}>
											COMPLETED PROJECTS
										</a>
                                   
                                </li>
                                <li className="nav-item ml-auto">
									<a className={tab =="COMPANY SETS"?'active':''} data-toggle="tab"  onClick={()=>{settab('COMPANY SETS')}}>
										CURRENT PORTFOLIO
									</a>
                                    
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="tab-content">
				{tab =="COMPLETED PROJECTS"?		
 <div id="port-1" className="tab-pane active">
                    <div className="row">

					{Object.entries(Contact.completed_projects|| {}).map(function ([index,value]) {
                      return  <div className="col-md-4">
                            <div className="content">
                                <Link to={'singleportfolio/'+value.id}>
                                    <img src={value.url} alt="port-image"/>
                                    <div className="cont">
                                        <h3>{value.address1}</h3>
                                        <h4>{value.address2}</h4>
                                    </div>
                                </Link>
                            </div>
                        </div>
						})}
                        
                        
                    </div>
    
                    
   </div>
   :""}

			{tab =="COMPANY SETS"?
                <div id="port-2" className="tab-pane active">
                    <div className="row">
					{Object.entries(Contact.current_portfolio|| {}).map(function ([index,value]) {
                        return <div className="col-md-4">
                            <div className="content">
                                <Link to={'singleportfolio/'+value.id}>
                                    <img src={value.url} alt="port-image"/>
                                    <div className="cont">
                                        <h3>{value.address1}</h3>
                                        <h4>{value.address2}</h4>
                                    </div>
                                </Link>
                            </div>
                        </div>
						})}

                    </div>
                </div>
				:""}

                </div>
            </div>
        </section>



			<section style={{display: 'none' }} id="hero1">
				{/* top item start */}
				<div className="container space-otherpage-top">
					<div className="lending-header">
						


						<Modal
							centered={model != false?true:false}
							isOpen={model}
							size="lg"
							toggle={()=>{setmodel(false)}}
						>
							<div className="modal-body">
								<Row>
									<Col className="col-12">
										<Iframe url={process.env.REACT_APP_BASE_URL+"portfohtml/"+model}
												width="100%"
												height="500px"
												id="myId"
												className="myClassname"
												display="initial"
												position="relative"/>
									</Col>

								</Row>

							</div>
						</Modal>





					</div>
					<div className="row pt-5">
						<div className="col-lg-12">
							<div className="container">
								<ul
									className="nav nav-tabs portfolio"
									style={{ paddingLeft: 0, paddingRight: 0 }}
								>
									<li className="portfolio-title">
										<a className={tab =="COMPLETED PROJECTS"?'active':''} data-toggle="tab" onClick={()=>{settab('COMPLETED PROJECTS')}}>
											COMPLETED PROJECTS
										</a>
									</li>
									<li className="portfolio-title">
										<a className={tab =="COMPANY SETS"?'active':''} data-toggle="tab"  onClick={()=>{settab('COMPANY SETS')}}>
											CURRENT PORTFOLIO
										</a>
									</li>
									{/*<li className="portfolio-title">
										<a className={tab =="CURRENT AVAILABILITIES"?'active':''} data-toggle="tab" onClick={()=>{settab('CURRENT AVAILABILITIES')}}>
											CURRENT AVAILABILITIES
										</a>
									</li>*/}
								</ul>
								<div className="tab-content">
									{tab =="COMPLETED PROJECTS"?
									<div id="home" className="tab-pane fade in active show">
										<div className="row">
											<div className="col-sm-12">
												<div className="d-flex flex-wrap justify-content-center">

													{Object.entries(Contact.completed_projects|| {}).map(function ([index,value]) {
														return <div className="p-2 flex-fill bd-highlight">
															<a
																className="fancybox fancybox.iframe portfolio-image portfolio-image"
																rel="group1"
																data-fancybox data-type="iframe"
																data-src={process.env.REACT_APP_BASE_URL+"portfohtml/"+value.id}
																href="javascript:;"
															>
																<img
																	className="img rounded mx-auto d-block portfolio_image "
																	src={value.url} onerror="this.src='/img3.jpg'"
																/>
																<span className="portfolio_lable_1">
                     														{value.address1}
                      												<p className="portfolio_lable_2">{value.address2}</p>
                    											</span>
																<div className="portfolio-overlay">
																	<p className="portfolio-overlay-title">
																		{value.address1}
																	</p>
																	<p className="portfolio-overlay-text">{value.address2}</p>
																</div>
															</a>
														</div>
													})}


												</div>
											</div>
										</div>
									</div>
									:""}
									{tab =="COMPANY SETS"?
										<div id="home" className="tab-pane fade in active show">
											<div className="row">
												<div className="col-sm-12">
													<div className="d-flex flex-wrap justify-content-center">

														{Object.entries(Contact.current_portfolio|| {}).map(function ([index,value]) {
															return <div className="p-2 flex-fill bd-highlight">
																<a
																	className="fancybox fancybox.iframe portfolio-image portfolio-image"
																	rel="group1"
																	data-fancybox data-type="iframe"
																	data-src={process.env.REACT_APP_BASE_URL+"portfohtml/"+value.id}
																	href="javascript:;"

																>
																	<img
																		className="img rounded mx-auto d-block portfolio_image "
																		src={value.url}
																	/>
																	<span className="portfolio_lable_1">
                     														{value.address1}
																		<p className="portfolio_lable_2">{value.address2}</p>
                    											</span>
																	<div className="portfolio-overlay">
																		<p className="portfolio-overlay-title">
																			{value.address1}
																		</p>
																		<p className="portfolio-overlay-text">{value.address2}</p>
																	</div>
																</a>
															</div>
														})}
													</div>
												</div>
											</div>
										</div>
										:""}
								</div>
							</div>
						</div>
					</div>
				</div>
				{/* top item end */}
				<br />
				<br />
				<br />
				<br />

			</section>
			<Footer/>

		</>
	)
};


export default About ;
