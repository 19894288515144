import React, {useEffect, useState} from 'react';
import {Link, useHistory} from "react-router-dom";
import Footer from "../../layout/footer";
import Mainsidebar from "./mainsidebar";
import HeaderNew from './headerNew'
import '../../assets/css/style.css'

import '../../assets/css/home.css'

const axios = require('axios');

const Testmonials = (props) => {

    const [Response, setResponse] = useState(false);
    const [Testmonials, setTestmonials] = useState(false);
    const [Pages, setPages] = useState(false);
    const [loader, Setloader] = useState(true);


    useEffect(() => {
        window.scrollTo(0, 0)
        getdatatoshow()
    }, []);

    const getdatatoshow = () => {
        axios.get(process.env.REACT_APP_BASE_URL+"auth/mediaget")
            .then(function (response) {
                if(response.data.error == 0)
                    setResponse(response.data.list[0])
                    setPages(response.data.list)
                    axios.get(process.env.REACT_APP_BASE_URL+"auth/testimonialList")
                        .then(function (response) {
                            if(response.data.error == 0)
                            setTestmonials(response.data.list)
                        })
                        .catch(function (error,response) {
                            console.log("error",error)
                        })
            })
            .catch(function (error,response) {
                console.log("error",error)
            })
            .then(function () {
                Setloader(false)
            });

    };
    var sectionStyle = {
        backgroundImage: `url(${Response.image})`
    };

    return (
        <>
            <HeaderNew homepage={false}/>
            <section className="slider-section about-banner" alt="about-banner-image">
                <img src={Response.image}/>
                <h2 className="text-center">{Response.page}</h2>
		    </section>

            <section className="investment-tab">
                <div className="container">
                    <div className="row">
                        <div className="col-md-5">
                            <div className="tab-list">
                                <ul className="nav nav-tabs">
                                    {
                                        Object.entries(Pages||{}).map(function ([index, value]) {
                                            return <li className="nav-item"><Link className="nav-link active" to={value.page.replace(/\s+/g, '-')}>{value.page}</Link></li>
                                        })
                                    }
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-7">
                            <div className="tab-content">
                                {
                                    Object.entries(Testmonials||{}).map(function ([index, value]) {
                                        return  <div className="">
                                                    <blockquote><p>{value.text_description}</p></blockquote>
                                                    <p>{value.name}</p>
                                                    <hr className="testimonial_line"/>
                                                </div>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer/>

        </>
    )
};


export default Testmonials ;
