import { Link, useHistory } from "react-router-dom";
import React, {useEffect, useState,Component } from 'react';
import ScrollToTop from "react-scroll-to-top";

const Footer = () => {
    return (
        <>
           <div id="footer_bg" className="footer_4_col footer_type_2">
                <div className="container">
                <div id="footerr">
                <div className="widget_wrap">
                <div className="widget widget_text"> <div className="textwidget"><p><img className="white_logo desktop_logo" src="/WEB-logo-footer4.png" alt=""/></p>
                </div>
                </div> </div>
                <div className="widget_wrap">
				
				<div className="widget widget_text contact-footer">			<div className="textwidget"><p className="heading-cont"><strong>Phantom CAPITAL</strong></p>
                    <p>825 Northern Blvd Ste. 303<br/>
                    Great Neck, NY 11021<br/>
                    </p>
                    <p><i className="fa fa-phone"></i> 212-730-2235</p>
                    <p><i className="fa fa-fax"></i> 718-223-4535</p>
                    <p><a href="mailto:office@phantomcapny.com"><i className="fa fa-envelope "></i>office@phantomcapny.com</a></p>
                    </div>
                            </div>
                                    
				
				</div>
			<div className="widget_wrap">
                <div className="widget widget_text"> 
                    <div className="textwidget">
                        <ul>
                            <li><Link to="/">Home</Link></li>
                            <li><Link to="/overview">About Us</Link></li>
                            <li><Link to="/lending">Lending</Link></li>
                            <li><Link to="/investment-strategy">Investment</Link></li>
                            <li><Link to="/portfolio">Portfolio</Link></li>
                            <li><Link to="/contact">Contact</Link></li>
                        </ul>
                    </div>
                </div> 
            </div>
            <div className="widget_wrap">
                <div className="widget rd_social_widget">
                    <h2>CONNECT WITH US</h2>
                    <div className="sc_small_line widget_line"><span className="small_l_left"></span></div>
                    <div className="thefox_social_widget">
                        <div className="thefox_social_widget_icons clearfix"> 
                            {/* <div id="yt"> <Link to="#" target="_blank"><i className="fa fa-youtube"></i></Link></div> */}
                            <div id="twitter"> <a href="https://twitter.com/capital_phantom" target="_blank"><i className="fa fa-twitter"></i></a></div>
                            <div id="facebook"> <a href="https://www.facebook.com/PhantomCapNY" target="_blank"><i className="fa fa-facebook"></i></a></div>
                            <div id="lin"> <a href="https://www.linkedin.com/company/phantom-capital-ny" target="_blank"><i className="fa fa-linkedin"></i></a></div>
                            <div id="instagram"> <a href="https://instagram.com/phantomcapitalny?utm_medium=copy_link" target="_blank"><i className="fa fa-instagram"></i></a></div>
                        </div>
                    </div>
                </div> 
            </div>
                </div>
                </div>

                <div id="footer_coms">
                <div className="container">

                <div className="f_menu_left m_normal">
                </div>
                <div className="footer_message f_message_left">

                © 2021 PHANTOM CAPITAL &nbsp;&nbsp;|&nbsp;&nbsp; ALL RIGHTS RESERVED</div>


                </div>
                </div>
                </div>

        </>
    )
};

export default Footer;
