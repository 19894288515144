import React from "react";
import PropTypes from "prop-types";
// import '../../assets/css/team.css'

const TeamImage = ({ data }) => (
    <div className="col-md-6">
        <div className="single-content">
            <img src={data.image} roundedCircle></img>
            <div className="text-content">
                <h4>{data.name}</h4>
                <h5>{data.designation}</h5>
            </div>
        </div>
    </div>
);

TeamImage.propTypes = {
    data: PropTypes.object.isRequired
};

export default TeamImage;
