import React, {useEffect, useState} from 'react';
import Footer from "../../layout/footer";
import SubDomainHeader from './subDomainHeader'
import SweetAlert from 'react-bootstrap-sweetalert';
import { CountryDropdown } from 'react-country-region-selector';

import '../../assets/css/home.css'

const axios = require('axios');

const InvestNow = (props) => {

    const [InvestFormdata, SetInvestdata] = useState({});
	const [InvestError, SetInvestError] = useState({});
	const [InvestSuccess, SetInvestSuccess] = useState(false)


    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    const optionsdata = new Object()
	optionsdata['options'] = {}

    const clearform = () =>{
		var form = document.getElementsByClassName('form-control');
		if(form.length > 0){
			var k;
			for(k=0;k<form.length;k++){
				form[k].value='';
			}
		}
	}

	const investForm = (event) =>{
		console.log(event)
		event.preventDefault();
		//SetInvestdata({ ...InvestFormdata, ['options']: seldata })
		console.log(InvestFormdata)
		
		axios.post(process.env.REACT_APP_BASE_URL+'auth/investor', {...InvestFormdata,'options':optionsdata['options']})
		.then(function (response) {
			SetInvestSuccess(true)
			clearform();
			SetInvestError({})
		})
		.catch(function (error,response) {
			SetInvestError(error.response.data.errors)
		});
	}

    return (
        <>

        <SubDomainHeader homepage={false} contact={true}/>

        <section className="contact-form">
            <div className="container">
                <div className="row">
                    <div className="col-md-8">
                        <div className="left-block">
                            <div className="col-ms-12">
                                <ul>
                                    {
                                        Object.entries(InvestError||{}).map(function ([index, value]) {
                                            return <li className="text-danger text-left">{value[0]}</li>
                                        })
                                    }
                                </ul>
                            </div>
                            <div className="col-ms-12">
                                <h2>Invest Now</h2>
                                <br></br>
                                <p>If what we do at Phantom Capital is interesting to you and you are an accredited investor, please contact us to learn more about Investment Opportunities. Thank you, The Phantom Capital Team</p>
                                <br></br>
                            </div>
                            <form  method="post" onSubmit={(e)=>{investForm(e)}} >
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group mt-2">
                                            <label htmlFor="formrow-firstname-input">Name</label>
                                            <input type="text" className="form-control" placeholder="" name="name"
                                            onBlur={(event)=>{SetInvestdata({ ...InvestFormdata, ['name']: event.target.value })}}
                                            defaultValue={InvestFormdata.name}
                                            required/>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group mt-2">
                                            <label htmlFor="formrow-firstname-input">Email</label>
                                            <input type="email" className="form-control" placeholder="" name="email"
                                            onBlur={(event)=>{SetInvestdata({ ...InvestFormdata, ['email']: event.target.value })}}
                                            defaultValue={InvestFormdata.email} 
                                            required/>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="formrow-firstname-input">Phone</label>
                                            <input type="text" className="form-control" placeholder="" name="phone"
                                            onBlur={(event)=>{SetInvestdata({ ...InvestFormdata, ['phone']: event.target.value })}}
                                            defaultValue={InvestFormdata.phone}
                                            required/>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="formrow-firstname-input">Country</label>
                                            <CountryDropdown
                                                className="form-control"
                                                value={InvestFormdata.country}
                                                onChange={(value)=>{SetInvestdata({ ...InvestFormdata, ['country']: value })}} />
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="formrow-firstname-input">Are you an Accredited Investor?</label>
                                    <select className="form-control" onChange={(event)=>{SetInvestdata({ ...InvestFormdata, ['is_accredited']: event.target.value })}}>
                                        <option vlaue="">-- Select --</option>
                                        <option value="Yes">Yes</option>
                                        <option vaule="No">No</option>
                                    </select>
                                </div>
                                
                                <div className="form-group">
                                    <label htmlFor="formrow-firstname-input">Expected Investment Amount</label>
                                    <select className="form-control" onChange={(event)=>{SetInvestdata({ ...InvestFormdata, ['investment_amount']: event.target.value })}}>
                                        <option vlaue="">-- Select --</option>
                                        <option value="$25,000 - $50,000">$25,000 - $50,000</option>
                                        <option vaule="$50,000 - $100,000">$50,000 - $100,000</option>
                                        <option value="$100,000 - $250,000">$100,000 - $250,000</option>
                                        <option value="$250,000 - $500,000">$250,000 - $500,000</option>
                                        <option value="$500,000 - $1,000,000">$500,000 - $1,000,000</option>
                                        <option value="$1,000,000+">$1,000,000+</option>
                                    </select>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="formrow-firstname-input">Referred By</label>
                                    <select className="form-control" onChange={(event)=>{SetInvestdata({ ...InvestFormdata, ['referred_by']: event.target.value })}}>
                                        <option vlaue="">-- Select --</option>
                                        <option value="Current Investor">Current Investor</option>    
                                        <option value="Eric Sussman">Eric Sussman</option>    
                                        <option value="Website/Google Search">Website/Google Search</option>    
                                        <option value="Conference">Conference</option>    
                                        <option value="Facebook">Facebook</option>    
                                        <option value="Linkedin">Linkedin</option>    
                                        <option value="Instagram">Instagram</option>    
                                        <option value="Twitter">Twitter</option>    
                                        <option value="Employee">Employee</option>    
                                        <option value="Tania Mirchandani">Tania Mirchandani</option>    
                                        <option value="Enrique Huerta">Enrique Huerta</option>    
                                        <option value="Ad - LinkedIn">Ad - LinkedIn</option>    
                                        <option value="Ad - Google">Ad - Google</option>
                                        <option value="Ad - Facebook">Ad - Facebook</option>
                                        <option value="Prospective Investor">Prospective Investor</option>   
                                    </select>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="formrow-firstname-input">Message</label>
                                    <textarea className="form-control" rows="5" placeholder="" onBlur={(event)=>{SetInvestdata({ ...InvestFormdata, ['message']: event.target.value })}}
                                    defaultValue={InvestFormdata.message}></textarea>
                                </div>
                                
                                <div className="btn-block">
                                    <input type="submit" className="btn btn-info" name="submit" value="Submit"/>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="col-md-4 hidden-xs hidden-sm lft-lg-img">
                        <div>
                            <img src="/img/login-img1.jpg"/>
                            <img src="/img/login-img2.jpg"/>
                            <img src="/img/login-img3.jpg"/>
                        </div>
                    </div>
                </div>
            </div>
        </section>        


        <Footer/>
        < SweetAlert
            title={"Thank You!"}
            type={"success"}
            show={InvestSuccess}
            onConfirm={()=>{SetInvestSuccess(false)}}
        >
            <h3>Someone from our team will reach out to you soon.</h3>
        </SweetAlert>

        </>
    )
};


export default InvestNow ;
