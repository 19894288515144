import React, {useEffect, useState} from 'react';
import {Link, useHistory} from "react-router-dom";
import Footer from "../../layout/footer";
import Mainsidebar from "./mainsidebar";
import HeaderNew from './headerNew'
import '../../assets/css/style.css'
import moment from 'moment';

import '../../assets/css/home.css'

import ReactHtmlParser from 'react-html-parser';

const axios = require('axios');

const NewsLetter = (props) => {

    const [Response, setResponse] = useState(false);
    const [loader, Setloader] = useState(true);


    useEffect(() => {
        window.scrollTo(0, 0)
        getdatatoshow()
    }, []);

    const getdatatoshow = () => {
        axios.get(process.env.REACT_APP_BASE_URL+"auth/newsletterList")
            .then(function (response) {
                if(response.data.error == 0)
                    console.log(response.data.list);
                    setResponse(response.data.list)
            })
            .catch(function (error,response) {
                console.log("error",error)
            })
            .then(function () {
                Setloader(false)
            });

    };

    return (
        <>
            <HeaderNew homepage={false} contact={true}/>
            <div className="jumbotron jumbotron-fluid">
                <div className="container">
                    <h3 class="display-4">NewsLetter</h3>
                </div>
            </div>

            <section className="porfolio">
                <div className="container">
                    <div className="row">
                        <div className="col-md-9">
                            <div className="tab-content">
                                {
                                    Object.entries(Response||{}).map(function ([index, value]) {
                                        return <div className="card">
                                        <div className="card-body">
                                          <h5 className="card-title newsletter-title">{value.title}</h5>
                                          <p className="span-content">
                                            <span>Posted by: Phantom Capital Admin  </span> |
                                            <span>  NewsLetter  </span> |
                                            <span>  Comments are closed </span> |
                                            <span>  {moment(value.created_at).format('DD MMM, YYYY')}</span>
                                          </p>
                                          <p className="card-text">{value.description.substr(0, 350)}...</p>
                                          <Link to={'single-news-letter/'+value.id} className="btn btn-outline-dark">Read More</Link>
                                        </div>
                                      </div>
                                    })
                                }
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="recent_posts">
                                <h3>RECENT POSTS</h3>
                                <ul className="list-group">
                                    {
                                        Object.entries(Response||{}).slice(0, 8).map(function ([index, value]){
                                            return <li className="list-group-item list-group-item-action"><Link to={'single-news-letter/'+value.id}>{value.title}</Link></li>
                                        })
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer/>

        </>
    )
};


export default NewsLetter ;
