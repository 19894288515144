import React, {useEffect, useState} from 'react';
import {Link, useHistory, useParams} from "react-router-dom";
import Footer from "../../layout/footer";
import Mainsidebar from "./mainsidebar";
import HeaderNew from './headerNew'
import '../../assets/css/style.css'

import '../../assets/css/home.css'

import ReactHtmlParser from 'react-html-parser';

const axios = require('axios');

const NewsLetterDetail = (props) => {

    const [Response, setResponse] = useState(false);
    const [loader, Setloader] = useState(true);

    let {id} = useParams();

    useEffect(() => {
        window.scrollTo(0, 0)
        getdatatoshow()
    }, []);

    const getdatatoshow = () => {
        axios.get(process.env.REACT_APP_BASE_URL+"auth/newsletterList/"+id)
            .then(function (response) {
                if(response.data.error == 0)
                    setResponse(response.data.list)
            })
            .catch(function (error,response) {
                console.log("error",error)
            })
            .then(function () {
                Setloader(false)
            });

    };

    return (
        <>
            <HeaderNew homepage={false} contact={true}/>

            <section className="porfolio">
                <div className="container">
                    <div className="row">
                        <div className="col-md-9">
                            <div className="tab-content">
                                <h1><u>{Response.title}</u></h1>
                                {/* <span>{`${Response.created_at ? new Date(Response.updatedAt).toDateString() : ""}`}</span> */}
                                <br></br>
                                <br></br>
                                <p>{Response.description}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
           
            <Footer/>

        </>
    )
};


export default NewsLetterDetail ;
