import { gridLayer } from 'leaflet';
import React, {useEffect, useState} from 'react';
import {Link, useHistory} from "react-router-dom";
const HeaderNew = (props) => {
	const contactPage = props.hasOwnProperty('contact')?'contactts':'';
	const subDomain = 'https://investors.phantomcapny.com';

	useEffect(() => {
    }, []);

	return (
        <>
            <header className={contactPage}>
				<div className="container">

					<nav className="navbar navbar-expand-lg">
						<Link className="navbar-brand" to="/">
							<img className="white_logo desktop_logo" src="/img/logo.png" alt="Phantom Captial" title="Phantom Captial"/>
							<img className="dark_logo desktop_logo" src="/img/logo.png" alt="Phantom Captial" title="Phantom Capital"/>
						</Link>
						<button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
							<span className="navbar-toggler-icon"></span>
						</button>

						<div className="collapse navbar-collapse" id={contactPage ? "navbarSupportedContent2" : "navbarSupportedContent"}>
							<ul className="navbar-nav ">
								{/* <li className="nav-item active">
									<Link className="nav-link" to="/about">About</Link>
								</li> */}
								{/* <li className="nav-item">
									<Link className="nav-link" to="http://www.phantomrealtynyc.com">Listings</Link>
								</li> */}

								<li className="nav-item dropdown">
									<a className="nav-link dropdown-toggle" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
									About
									</a>
									<div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
										<Link className="dropdown-item" to="/overview">OVERVIEW</Link>
										<Link className="dropdown-item" to="/history">HISTORY</Link>
										<Link className="dropdown-item" to="/our-values">OUR VALUES</Link>
										{/* <Link className="dropdown-item" to="/our-team">OUR TEAM</Link> */}
									</div>
								</li>

								<li className="nav-item">
									<Link className="nav-link" to="/lending">Lending</Link>
								</li>

								{/* <li className="nav-item">
									<Link className="nav-link" to="/invest-with-us">Investment</Link>
								</li> */}

								<li className="nav-item dropdown">
									<a className="nav-link dropdown-toggle" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
									Investment
									</a>
									<div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
										<Link className="dropdown-item" to="/investment-strategy">INVESTMENT STRATEGY</Link>
										<Link className="dropdown-item" to="/investment-opportunities">INVESTMENT OPPOTUNITIES</Link>
										<Link className="dropdown-item" to="/clearly-defined-acquisition-criteria">CLEARLY DEFINED AQUISITION</Link>
										{/* <Link class="dropdown-item" to="/invest-now">INVEST NOW</Link> */}
										<a className="dropdown-item" target="_blank" href={subDomain+'/invest-now'}>INVEST NOW</a>
									</div>
								</li>

								<li className="nav-item dropdown">
									<a className="nav-link dropdown-toggle" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
									Media
									</a>
									<div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
										<Link className="dropdown-item" to="/testimonials">TESTIMONIALS</Link>
										<Link className="dropdown-item" to="/videos">VIDEOS</Link>
										<Link className="dropdown-item" to="/news-letter">NEWSLETTER</Link>
									</div>
								</li>


								<li className="nav-item">
									<Link className="nav-link" id="portfolio-btn" to="/portfolio">Portfolio</Link>
								</li>

								<li className="nav-item">
									<Link className="nav-link" to="/contact">Contact</Link>
								</li>

								<li className="nav-item">
									<a className="nav-link" target="_blank" href={subDomain+'/login'}>Investor Login</a>
								</li>

							</ul>
						</div>
					</nav>
				</div>
			</header>
        </>
    );
};


export default HeaderNew;
