import React, {useEffect, useState} from 'react';
import '../../assets/css/home.css'
import {Link, useHistory} from "react-router-dom";
import Footer from "../../layout/footer";
import Mainsidebar from "./mainsidebar";
import GoogleMapReact from 'google-map-react';
import HeaderNew from './headerNew'
import SweetAlert from 'react-bootstrap-sweetalert';

const axios = require('axios');
const Contact = (props) => {
    const [Contact, setContact] = useState(false);
    const [loader, Setloader] = useState(true);
		const [contactformdata, contactdata] = useState({});
		const [contacterror, setcontacterror] = useState({});
		const [contactSuccess, setContactSuccess] = useState(false)
		//const [sel, setsel] = useState([]);
		//const [contactformdata, contactdata] = useState({});

    useEffect(() => {
		window.scrollTo(0, 0)
		Setloader(false)
    }, []);

		const scroolevent = (event)=> {
				window.scrollTo(0,0);
		}

		const clearform = () =>{
				var form = document.getElementsByClassName('form-control');
				if(form.length > 0){
						var k;
						for(k=0;k<form.length;k++){
								form[k].value='';
						}
				}
		}

		const contactform = (event) =>{
				event.preventDefault();
				console.log(optionsdata);
				contactdata({ ...contactformdata, ['options']: seldata })
				console.log(contactformdata)

				axios.post(process.env.REACT_APP_BASE_URL+'auth/contact-us', {...contactformdata,'options':optionsdata['options']})
				.then(function (response) {
					setContactSuccess(true)
					contactdata({})
					clearform();
					setcontacterror({})
				})
				.catch(function (error,response) {
					setcontacterror(error.response.data.errors)
				});
		}

    const seldata = []

		const optionsdata = new Object()
		optionsdata['options'] = {}

		const setvalues = () =>{
			console.log(seldata)
		}

		const handle = (event) =>{
				var index = seldata.indexOf(event.target.value);
				if (index !== -1){
					seldata.splice(index, 1)
				} else{
					seldata.push(event.target.value)
				}
				optionsdata['options'] = seldata;
				//setsel({ ...contactformdata, ['options']: seldata })
				console.log(seldata)
				console.log(optionsdata)
				//setvalues();
				//return false;
		}


    return (
        <>
            <HeaderNew homepage={false} contact={true}/>
           	{/*   <section className="slider-section about-banner" alt="about-banner-image">
		    <img src="/img19.jpg"/>
            <h2 class="text-center">Contact-Us</h2>
		</section>*/}
                {/*Google map*/}
			<div
				id="map-container-google-1"
				className="z-depth-1-half map-container custom-map-container blog2"
			>
				<iframe
					src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3020.9408835983563!2d-73.7123228845928!3d40.78531377932408!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c289afd7a75cc9%3A0x19a2520d10857da4!2s825%20Northern%20Blvd%20%23303%2C%20Great%20Neck%2C%20NY%2011021%2C%20USA!5e0!3m2!1sen!2s!4v1621365593357!5m2!1sen!2s"
					// src="https://maps.google.com/maps?q=124-15+Rockaway+Blvd+Ozone+Park+NY+11420&t=&z=13&ie=UTF8&iwloc=&output=embed"
					frameBorder={0}
					style={{ border: 0,}}
					allowFullScreen
				/>
				{/*<GoogleMapReact
					bootstrapURLKeys={{ key:  YOUR KEY HERE  }}
					defaultCenter={ {
						lat: 59.95,
						lng: 30.33
					}}
					defaultZoom={{zoom: 11}}
				>
					<AnyReactComponent
						lat={59.955413}
						lng={30.337844}
						text="My Marker"
					/>
				</GoogleMapReact>*/}



			</div>

			{/*Google Maps*/}


			<section className="contact-form">
				<div className="container">
					<div className="row">
						<div className="col-md-4">
							<div className="left-block" style={{color:'black'}}>
								<div className="add-block">
									<h5>Phantom Capital</h5>
									<p>825 Northern Blvd Ste. 303<br/>
					                    Great Neck, NY 11021<br/></p>
									<p className="mb-0"><i className="fa fa-phone"></i>212-730-2235</p>
									<p className="mb-0"><i className="fa fa-fax"></i>718-223-4535</p>
									<p className="mb-0"><i className="fa fa-envelope"></i><a href="mailto:office@phantomcapny.com">office@phantomcapny.com</a></p>
								</div>

							</div>
						</div>
						<div className="col-md-8">
							<div className="right-block">
								<div className="col-ms-12">
									<ul>
									{
										Object.entries(contacterror||{}).map(function ([index, value]) {
											return <li className="text-danger text-left">{value[0]}</li>
										})
									}
									</ul>
								</div>
								<form  method="post"  onSubmit={(e)=>{contactform(e)}} >
									<div className="form-group mt-2">
										<input type="text" className="form-control" placeholder="Full Name" name="name"
										onBlur={(event)=>{contactdata({ ...contactformdata, ['name']: event.target.value })}}
										defaultValue={contactformdata.name}
										required/>
									</div>
									<div className="form-group email position-relative">
									<input type="email" className="form-control" placeholder="Email" name="email"
									onBlur={(event)=>{contactdata({ ...contactformdata, ['email']: event.target.value })}}
									defaultValue={contactformdata.email}
									required/>
									<i className="fa fa-envelope-o icon-mail"></i>
									</div>
									<div className="form-group">
									<input type="text" className="form-control" placeholder="Phone" name="phone"
									onBlur={(event)=>{contactdata({ ...contactformdata, ['phone']: event.target.value })}}
									defaultValue={contactformdata.phone}
									required/>
									</div>
									<div className="form-group form-check">
									<label className="form-check-label">
										<input className="form-check-input" type="checkbox" name="mob" value="mobile" onClick={handle}/>Mobile
									</label>
									<label className="form-check-label">
										<input className="form-check-input" type="checkbox" name="work" value="work" onClick={handle} />Work
									</label>
									<label className="form-check-label">
										<input className="form-check-input" type="checkbox" name="home" value="home" onClick={handle} />Home
									</label>
									</div>
									<div className="form-group">
									<select className="form-control" onChange={(event)=>{contactdata({ ...contactformdata, ['select']: event.target.value })}}>
										<option vlaue="">Select An Option</option>
										<option value="General Inquiries">General Inquiries</option>
										<option vaule="Submit a Deal">Submit a Deal</option>
										<option value="Investor Relations">Investor Relations</option>
										<option value="Careers">Careers</option>
										<option value="Other">Other</option>
									</select>
									</div>
									<div className="form-group">
										<input type="text" className="form-control" placeholder="Subject" name="subject"
										onBlur={(event)=>{contactdata({ ...contactformdata, ['subject']: event.target.value })}}
										defaultValue={contactformdata.subject}
										required/>
									</div>
									<div className="form-group">
									<textarea className="form-control" rows="5" placeholder="How can we help you?" onBlur={(event)=>{contactdata({ ...contactformdata, ['message']: event.target.value })}}
										defaultValue={contactformdata.message}></textarea>
									</div>
									<div className="btn-block">
										<input type="submit" className="btn btn-info" name="submit" value="Submit"/>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</section>



			<Footer/>
            < SweetAlert
				title={"Thank You!"}
				type={"success"}
				show={contactSuccess}
				onConfirm={()=>{setContactSuccess(false)}}
			>
			    <h3>Someone from our team will reach out to you soon.</h3>
			</SweetAlert>
        </>
    )
};


export default Contact ;
