import React, {useEffect, useState} from 'react';
import {Link, useHistory} from "react-router-dom";
import Footer from "../../layout/footer";
import HeaderNew from './headerNew'
import Mainsidebar from "./mainsidebar";

import '../../assets/css/home.css'

const axios = require('axios');

const InvestmentOppotunities = (props) => {

	const subDomain = 'https://investors.phantomcapny.com';
    const [Response, setResponse] = useState(false);
    const [tab, settab] = useState('Acquisitions');
    const [Images, setimages] = useState([]);
    const [details, setdetails] = useState([]);
    const [loader, Setloader] = useState(true);
    const [Pages, setPages] = useState(false);


    useEffect(() => {
        window.scrollTo(0, 0)
        getdatatoshow()
    }, []);

    const getdatatoshow = () => {
        axios.get(process.env.REACT_APP_BASE_URL+"auth/investwithusget")
            .then(function (response) {
                if(response.data.error == 0)
                {
                    setResponse(response.data.list[1])
                    setPages(response.data.list)
                    setimages(response.data.images)
                    setdetails(response.data.details)
                }
            })
            .catch(function (error,response) {
                console.log("error",error)
            }).then(function () {
            Setloader(false)
        });

    };
    var sectionStyle = {
        backgroundImage: `url(${Response.image})`
    };

    return (
        <>

        <HeaderNew homepage={false}/>
        <section className="slider-section about-banner" alt="invest-banner-image">
		    <img src={Response.image}/>
            <h2 className="text-center">{Response.page}</h2>
		</section>

        <section className="investment-tab">
            <div className="container">
                <div className="row">
                    <div className="col-md-5">
                        <div className="tab-list">
                            <ul className="nav nav-tabs">
                                {
                                    Object.entries(Pages||{}).map(function ([index, value]) {
                                        if(value.page == 'invest now') {
                                            return <li className="nav-item"> <a className="nav-link active" target="_blank" href={subDomain+ '/' +value.page.replace(/\s+/g, '-')}>{value.page}</a></li>
                                        } else {
                                            return <li className="nav-item"><Link className="nav-link active" to={value.page.replace(/\s+/g, '-')}>{value.page}</Link></li>
                                        }
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-7">
                        <div className="tab-content">
                            {Response.discription}
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <Footer/>

        </>
    )
};


export default InvestmentOppotunities ;
