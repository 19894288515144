import { gridLayer } from 'leaflet';
import React, {useEffect, useState} from 'react';
import {Link, useHistory} from "react-router-dom";
const HeaderNew = (props) => {
	const contactPage = props.hasOwnProperty('contact')?'contactts':'';
	const domain = 'https://phantomcapny.com';
	const subDomain = 'https://investors.phantomcapny.com';

	useEffect(() => {
    }, []);

	return (
        <>
            <header className={contactPage}>
				<div className="container">

					<nav className="navbar navbar-expand-lg">
						<a className="navbar-brand" href={subDomain+'/login'}>
							<img className="white_logo desktop_logo" src="/img/logo.png" alt="Phantom Captial" title="Phantom Captial"/>
							<img className="dark_logo desktop_logo" src="/img/logo.png" alt="Phantom Captial" title="Phantom Capital"/>
						</a>
						<button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
							<span className="navbar-toggler-icon"></span>
						</button>

						<div className="collapse navbar-collapse" id={contactPage ? "navbarSupportedContent2" : "navbarSupportedContent"}>
							<ul className="navbar-nav ">
								<li className="nav-item">
									<a class="nav-link" href={domain}>Return to the main Phantom Capital, LLC website</a>
								</li>
							</ul>
						</div>
					</nav>
				</div>
			</header>
        </>
    );
};


export default HeaderNew;
